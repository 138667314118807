// all diacritics
let diacritics = {
  a: [
    "a",
    "à",
    "á",
    "â",
    "ã",
    "ä",
    "å",
    "æ",
    "ā",
    "ă",
    "ą",
    "ǎ",
    "ǟ",
    "ǡ",
    "ǻ",
    "ȁ",
    "ȃ",
    "ȧ",
    "ɐ",
    "ɑ",
    "ɒ",
    "ͣ",
    "а",
    "ӑ",
    "ӓ",
    "ᵃ",
    "ᵄ",
    "ᶏ",
    "ḁ",
    "ẚ",
    "ạ",
    "ả",
    "ấ",
    "ầ",
    "ẩ",
    "ẫ",
    "ậ",
    "ắ",
    "ằ",
    "ẳ",
    "ẵ",
    "ặ",
    "ₐ",
    "ⱥ",
    "ａ",
  ],
  b: ["b", "ƀ", "ƃ", "ɓ", "ᖯ", "ᵇ", "ᵬ", "ᶀ", "ḃ", "ḅ", "ḇ", "ｂ"],
  c: ["c", "ç", "ć", "ĉ", "ċ", "č", "ƈ", "ȼ", "ɕ", "ͨ", "ᴄ", "ᶜ", "ḉ", "ↄ", "ｃ"],
  d: [
    "d",
    "ď",
    "đ",
    "Ƌ",
    "ƌ",
    "ȡ",
    "ɖ",
    "ɗ",
    "ͩ",
    "ᵈ",
    "ᵭ",
    "ᶁ",
    "ᶑ",
    "ḋ",
    "ḍ",
    "ḏ",
    "ḑ",
    "ḓ",
    "ｄ",
  ],
  e: [
    "e",
    "è",
    "é",
    "ê",
    "ë",
    "ē",
    "ĕ",
    "ė",
    "ę",
    "ě",
    "ǝ",
    "ȅ",
    "ȇ",
    "ȩ",
    "ɇ",
    "ɘ",
    "ͤ",
    "ᵉ",
    "ᶒ",
    "ḕ",
    "ḗ",
    "ḙ",
    "ḛ",
    "ḝ",
    "ẹ",
    "ẻ",
    "ẽ",
    "ế",
    "ề",
    "ể",
    "ễ",
    "ệ",
    "ₑ",
    "ｅ",
  ],
  f: ["f", "ƒ", "ᵮ", "ᶂ", "ᶠ", "ḟ", "ｆ"],
  g: ["g", "ĝ", "ğ", "ġ", "ģ", "ǥ", "ǧ", "ǵ", "ɠ", "ɡ", "ᵍ", "ᵷ", "ᵹ", "ᶃ", "ᶢ", "ḡ", "ｇ"],
  h: [
    "h",
    "ĥ",
    "ħ",
    "ƕ",
    "ȟ",
    "ɥ",
    "ɦ",
    "ʮ",
    "ʯ",
    "ʰ",
    "ʱ",
    "ͪ",
    "Һ",
    "һ",
    "ᑋ",
    "ᶣ",
    "ḣ",
    "ḥ",
    "ḧ",
    "ḩ",
    "ḫ",
    "ⱨ",
    "ｈ",
  ],
  i: [
    "i",
    "ì",
    "í",
    "î",
    "ï",
    "ĩ",
    "ī",
    "ĭ",
    "į",
    "ǐ",
    "ȉ",
    "ȋ",
    "ɨ",
    "ͥ",
    "ᴉ",
    "ᵎ",
    "ᵢ",
    "ᶖ",
    "ᶤ",
    "ḭ",
    "ḯ",
    "ỉ",
    "ị",
    "ｉ",
  ],
  j: ["j", "ĵ", "ǰ", "ɉ", "ʝ", "ʲ", "ᶡ", "ᶨ", "ｊ"],
  k: ["k", "ķ", "ƙ", "ǩ", "ʞ", "ᵏ", "ᶄ", "ḱ", "ḳ", "ḵ", "ⱪ", "ｋ"],
  l: [
    "l",
    "ĺ",
    "ļ",
    "ľ",
    "ŀ",
    "ł",
    "ƚ",
    "ȴ",
    "ɫ",
    "ɬ",
    "ɭ",
    "ˡ",
    "ᶅ",
    "ᶩ",
    "ᶪ",
    "ḷ",
    "ḹ",
    "ḻ",
    "ḽ",
    "ℓ",
    "ⱡ",
  ],
  m: ["m", "ɯ", "ɰ", "ɱ", "ͫ", "ᴟ", "ᵐ", "ᵚ", "ᵯ", "ᶆ", "ᶬ", "ᶭ", "ḿ", "ṁ", "ṃ", "㎡", "㎥", "ｍ"],
  n: [
    "n",
    "ñ",
    "ń",
    "ņ",
    "ň",
    "ŉ",
    "ƞ",
    "ǹ",
    "ȵ",
    "ɲ",
    "ɳ",
    "ᵰ",
    "ᶇ",
    "ᶮ",
    "ᶯ",
    "ṅ",
    "ṇ",
    "ṉ",
    "ṋ",
    "ⁿ",
    "ｎ",
  ],
  o: [
    "o",
    "ò",
    "ó",
    "ô",
    "õ",
    "ö",
    "ø",
    "ō",
    "ŏ",
    "ő",
    "ơ",
    "ǒ",
    "ǫ",
    "ǭ",
    "ǿ",
    "ȍ",
    "ȏ",
    "ȫ",
    "ȭ",
    "ȯ",
    "ȱ",
    "ɵ",
    "ͦ",
    "о",
    "ӧ",
    "ө",
    "ᴏ",
    "ᴑ",
    "ᴓ",
    "ᴼ",
    "ᵒ",
    "ᶱ",
    "ṍ",
    "ṏ",
    "ṑ",
    "ṓ",
    "ọ",
    "ỏ",
    "ố",
    "ồ",
    "ổ",
    "ỗ",
    "ộ",
    "ớ",
    "ờ",
    "ở",
    "ỡ",
    "ợ",
    "ₒ",
    "ｏ",
    "𐐬",
  ],
  p: ["p", "ᵖ", "ᵱ", "ᵽ", "ᶈ", "ṕ", "ṗ", "ｐ"],
  q: ["q", "ɋ", "ʠ", "ᛩ", "ｑ"],
  r: [
    "r",
    "ŕ",
    "ŗ",
    "ř",
    "ȑ",
    "ȓ",
    "ɍ",
    "ɹ",
    "ɻ",
    "ʳ",
    "ʴ",
    "ʵ",
    "ͬ",
    "ᵣ",
    "ᵲ",
    "ᶉ",
    "ṙ",
    "ṛ",
    "ṝ",
    "ṟ",
  ],
  s: ["s", "ś", "ŝ", "ş", "š", "ș", "ʂ", "ᔆ", "ᶊ", "ṡ", "ṣ", "ṥ", "ṧ", "ṩ", "ｓ"],
  t: [
    "t",
    "ţ",
    "ť",
    "ŧ",
    "ƫ",
    "ƭ",
    "ț",
    "ʇ",
    "ͭ",
    "ᵀ",
    "ᵗ",
    "ᵵ",
    "ᶵ",
    "ṫ",
    "ṭ",
    "ṯ",
    "ṱ",
    "ẗ",
    "ｔ",
  ],
  u: [
    "u",
    "ù",
    "ú",
    "û",
    "ü",
    "ũ",
    "ū",
    "ŭ",
    "ů",
    "ű",
    "ų",
    "ư",
    "ǔ",
    "ǖ",
    "ǘ",
    "ǚ",
    "ǜ",
    "ȕ",
    "ȗ",
    "ͧ",
    "ߎ",
    "ᵘ",
    "ᵤ",
    "ṳ",
    "ṵ",
    "ṷ",
    "ṹ",
    "ṻ",
    "ụ",
    "ủ",
    "ứ",
    "ừ",
    "ử",
    "ữ",
    "ự",
    "ｕ",
  ],
  v: ["v", "ʋ", "ͮ", "ᵛ", "ᵥ", "ᶹ", "ṽ", "ṿ", "ⱱ", "ｖ", "ⱴ"],
  w: ["w", "ŵ", "ʷ", "ᵂ", "ẁ", "ẃ", "ẅ", "ẇ", "ẉ", "ẘ", "ⱳ", "ｗ"],
  x: ["x", "̽", "͓", "ᶍ", "ͯ", "ẋ", "ẍ", "ₓ", "ｘ"],
  y: ["y", "ý", "ÿ", "ŷ", "ȳ", "ɏ", "ʸ", "ẏ", "ỳ", "ỵ", "ỷ", "ỹ", "ｙ"],
  z: [
    "z",
    "ź",
    "ż",
    "ž",
    "ƶ",
    "ȥ",
    "ɀ",
    "ʐ",
    "ʑ",
    "ᙆ",
    "ᙇ",
    "ᶻ",
    "ᶼ",
    "ᶽ",
    "ẑ",
    "ẓ",
    "ẕ",
    "ⱬ",
    "ｚ",
  ],
};

// Precompiled Object with { key = Diacritic, value = real-Character }
const compiledDiactitics = (function() {
  let x = {};

  for (let key in diacritics) {
    let ok = diacritics[key];

    for (let rval in ok) {
      let val = ok[rval];

      // Do not replace the char with itself
      if (val !== key) {
        x[val] = key;
      }
    }
  }

  return x;
})();

// Regex for detecting non-ASCII-Characters in String
const regexNonASCII = /[^a-z0-9\s,.-]/;

/*
 * Main function of the module which removes all diacritics from the received text
 */
const diacriticless = (text) => {
  // When there are only ascii-Characters in the string, skip processing and return text right away
  if (text.search(regexNonASCII) === -1) {
    return text;
  }

  let result = "";

  let len = text.length;
  for (var i = 0; i < len; i++) {
    let searchChar = text.charAt(i);

    // If applicable replace the diacritic character with the real one or use the original value
    result += searchChar in compiledDiactitics ? compiledDiactitics[searchChar] : searchChar;
  }

  return result;
};

export {diacriticless};
